export const strict = false;
import banksQuery from "~/apollo/queries/bank/banks.gql";
import marks from "~/apollo/queries/marks";
import settings from "~/apollo/queries/settings";

export const state = () => ({
  site_id: null,
  domain: ""
});
export const getters = {
  site_id: state => {
    return state.site_id;
  },
  domain: state => {
    return state.domain;
  }
};
export const actions = {
  async nuxtServerInit({ dispatch, commit, state }, { req, app, $config, query }) {
    let site_id;
    
    // Устанавливаем разные site_id в зависимости от наличия utm_source
    if (query.utm_source) {
      site_id = 483; // site_id для переходов по рекламе
    } else {
      site_id = 393; // site_id по умолчанию
    }
    
    commit("SET_SITE_ID", site_id);
    commit("SET_DOMAIN", $config.domain);

    let client = app.apolloProvider.defaultClient;

    // Устанавливаем дефолтный процент
    commit("banks/SET_PERCENT", $config.default_percent);

    // Получаем настройки сайта
    let currentSettings = {};
    let settingsResponse = await client.query({
      query: settings,
      variables: { site_id }
    });
    settingsResponse.data.settings.settings.map(value => {
      currentSettings[value.key] = value.value;
    });

    // Получаем данные банков
    let banks = await client.query({
      query: banksQuery,
      variables: { site_id }
    });
    commit("settings/settings/SET_SETTINGS", currentSettings);
    commit("banks/SET_BANKS", banks.data.banks);

    // Получаем список марок для категории "cars"
    let response = await client.query({
      query: marks,
      variables: { site_id, category: "cars" }
    });
    commit("marks/marks/SET_ALL_MARKS", response.data.marks);

    // Получаем список марок для категории "new"
    let responseNew = await client.query({
      query: marks,
      variables: { site_id, category: "new" }
    });
    commit("marks/marks/SET_ALL_MARKS_NEW", responseNew.data.marks);
  },
};
export const mutations = {
  SET_SITE_ID(state, data) {
    state.site_id = data;
  },
  SET_DOMAIN(state, data) {
    state.domain = data;
  }
};
