export default function ({ store, app }) {
    const siteId = store.state.site_id;
  
    // Определяем путь к фавиконкам в зависимости от siteId
    const faviconPath = siteId === 483 ? '/favicon-gagarina' : '';
  
    // Устанавливаем иконки в зависимости от пути
    app.head.link = [
      { rel: 'icon', href: `${faviconPath}/favicon.ico`, sizes: 'any' },
      { rel: 'icon', href: `${faviconPath}/favicon.svg`, type: 'image/svg+xml' },
      { rel: 'mask-icon', href: `${faviconPath}/mask-icon.svg`, color: '#ED2939' },
      { rel: 'manifest', href: `${faviconPath}/manifest.json` },
      { rel: 'apple-touch-icon', href: `${faviconPath}/apple-touch-icon.png`, sizes: '180x180' },
      { rel: 'apple-touch-icon', href: `${faviconPath}/apple-touch-icon-512x512.png`, sizes: '512x512' },
      { rel: 'apple-touch-icon', href: `${faviconPath}/apple-touch-icon-120x120-precomposed.png`, sizes: '120x120' },
      { rel: 'apple-touch-icon', href: `${faviconPath}/apple-touch-icon-120x120.png`, sizes: '120x120' }
    ];
  }